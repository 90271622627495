import React, { useState } from "react";

import Placeholder from "./placeholder";
import Heading from "./Heading";
import { SaveChangesPopup } from "./savePopup";

const Home = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  async function handleSave() {}
  function handleClose() {
    setShowPopUp(false);
  }

  return (
    <div className="bg-[#F1F5F9] bg-cover w-screen min-h-screen">
      <div className="flex flex-row font-['Roboto']">
        <div className="flex flex-col">
          <h1
            className="lg:ml-[40px] lg:mt-[24px] 2xl:ml-[90px] 2xl:mt-[48px] font-['Roboto'] font-semibold lg:text-[40px] 2xl:text-4xl text-[#1A2338]"
            style={{ lineHeight: "78px" }}
          >
            Home Page
          </h1>
          <p className="my-2 font-bold lg:text-[24px] 2xl:text-xl text-sm 2xl:ml-[90px] lg:ml-[40px] text-[#1A2338B2] ">
            pop-up
          </p>
        </div>
      </div>
      <Heading />
      <Placeholder />
      <SaveChangesPopup
        onSave={handleSave}
        open={showPopUp}
        onClose={handleClose}
      />
    </div>
  );
};

export default Home;
