import React from "react";
import "./App.css";
import About from "./component/About/About";
import Admission from "./component/Admission/admission";
import Navbar from "./component/Home/Navbar";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ContactUsPage from "./component/contact/contact";
import Home from "./component/Home/Home";
import Campusbuzz from "./component/CampusBuzz/Campusbuzz";
import { ToastContainer } from "react-toastify";
import Login from "./component/Login/login";
import useLoginStore from "./store/useLoginStore";

function App() {
  const { isLoggedIn } = useLoginStore();

  return (
    <Router>
      <ToastContainer className="!z-[999999]" />
      <div className="flex w-full">
        {isLoggedIn && <Navbar />}
        <div className="flex-grow">
          <Routes>
            {!isLoggedIn ? (
              <>
                {/* Redirect all routes to Login if not logged in */}
                <Route path="*" element={<Login />} />
              </>
            ) : (
              <>
                {/* Routes accessible after login */}
                <Route path="/Home" element={<Home />} />
                <Route path="/About" element={<About />} />
                <Route path="/Admission" element={<Admission />} />
                <Route path="/ContactUsPage" element={<ContactUsPage />} />
                <Route path="/Campusbuzz" element={<Campusbuzz />} />
                {/* Redirect to Home by default */}
                <Route path="/" element={<Navigate replace to="/Home" />} />
                <Route path="*" element={<Navigate replace to="/Home" />} />
              </>
            )}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
