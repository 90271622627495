import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SaveButton from "../Buttons/saveButton";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Admission = () => {
  const [email, setEmail] = useState("");
  const [reEnterEmail, setReEnterEmail] = useState("");
  async function clearEmail() {}
  async function saveEmail() {
    let valid = false;
    if (reEnterEmail !== email) {
      toast.error("Please check the Email");
      valid = false;
    }
    if (reEnterEmail === email) {
      valid = true;
    }
    if (valid === false) return;
    try {
      const payload = {
        Email: email,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${API_BASE_URL}/Studentadmissions`,
        payload,
        config
      );

      if (response?.data?.status) {
        toast.success("Email saved successfully");
      }
      setEmail(response?.data?.data?.Email);
    } catch (e) {
      console.log("Error:", e);
    }
  }
  const getAdmission = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getAlladmissions`);
      setEmail(response.data.data?.Email);
      setReEnterEmail(response.data.data?.Email);
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getAdmission();
  }, []);

  return (
    <>
      <div className="font-['Roboto'] bg-[#F1F5F9] bg-cover w-[88vw] h-[100vh]">
        <div>
          <h1
            className="2xl:ml-[90px] lg:ml-[40px] pt-[48px]  text-[40px] font-semibold text-[#1A2338]"
            style={{ lineHeight: "78px" }}
          >
            Admissions Page
          </h1>
        </div>
        <div className="2xl:w-[769px]  2xl:h-[304px] lg:w-[680px]  lg:h-[304px] rounded 2xl:mt-[44px] lg:mt-[25px] bg-[#FFFFFF] 2xl:ml-[84px] lg:ml-[25px] ">
          <Box
            className="2xl:ml-[30px] lg:ml-[15px] pt-[51px]"
            component="form"
            sx={{
              "& > :not(style)": { m: 1 },
            }}
            autoComplete="off"
          >
            <TextField
              id="outlined-uncontrolled"
              label="email address"
              defaultValue="email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Example@gmail.com"
              sx={{ width: "642px", height: "56px" }}
            />
            <TextField
              id="outlined-uncontrolled"
              label="Re-enter email address"
              defaultValue="Example@gmail.com"
              value={reEnterEmail}
              onChange={(e) => setReEnterEmail(e.target.value)}
              placeholder="Example@gmail.com"
              sx={{ width: "642px", height: "56px" }}
            />
          </Box>
          <div className="2xl:ml-[531px] lg:ml-[490px] ">
            <SaveButton onSave={saveEmail} onCancel={clearEmail} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Admission;
