import React, { useEffect, useState } from "react";

import ImageUpload from "./imageUpload";
import PublishButton from "../publish/publishButton";
import { SaveChangesPopup } from "../Home/savePopup";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";

const About = () => {
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const [togglePop, setTogglePop] = useState(false);
  const [activeSlideId, setActiveSlideId] = useState(1);
  const [image, setImage] = useState(null);
  const openPopUp = (slideId) => {
    setActiveSlideId(slideId);
    setTogglePop(true);
    setToggleSwitch(!toggleSwitch);
  };
  const closePopUp = () => {
    setActiveSlideId(1);
    setTogglePop(false);
  };
  async function handleSwitch(e, activeSlideId) {
    // e.stopPropagation();
    try {
      const config = {
        method: "PUT",
        mode: "cors",
        url: `${API_BASE_URL}/updataabouteData/${1}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: { Published: toggleSwitch },
      };
      const response = await axios(config);
      console.log(response?.data?.status);
      if (response?.data?.status) {
        toast.success("Published Successfully");
      }
    } catch (e) {
      console.log("Error:", e);
    }
  }
  const onSaveChanges = async () => {
    // Close the popup first
    closePopUp();
    // Proceed with saving changes
    if (activeSlideId !== null) {
      await handleSwitch(activeSlideId);
    }
  };
  const getAboutData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getaboutData`);
      setImage(response.data.data?.Photo);
      setActiveSlideId(response.data.data?.id);
      setToggleSwitch(response.data.data?.Published);
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getAboutData();
  }, []);
  return (
    <>
      <div className="bg-[#F1F5F9] bg-cover w-screen min-h-screen">
        <div className="flex flex-row font-['Roboto']">
          <h1
            className="lg:ml-[40px] lg:mt-[24px] 2xl:ml-[90px] 2xl:mt-[48px] font-['Roboto'] font-semibold lg:text-lg 2xl:text-4xl text-[#1A2338]"
            style={{ lineHeight: "78px" }}
          >
            About Us
          </h1>
        </div>
        <div className="font-['Roboto'] flex flex-col  rounded-3xl 2xl:mt-[34px] lg:mt-[24px] bg-[#FFFFFF] w-1/2 h-[360px] 2xl:ml-[90px] lg:ml-[50px]">
          <div className="flex flex-row items-center">
            <h1 className="mb-[13px] ml-[30px] pt-[26px] font-bold text-sm text-left text-[#1A233899]">
              Photo
            </h1>
            <div className="ml-auto">
              {" "}
              {/* This pushes the PublishButton to the end of the flex container */}
              <PublishButton
                isPublished={toggleSwitch}
                openPopUp={() => openPopUp(1)}
                slideId={1}
              />
            </div>
          </div>

          <SaveChangesPopup
            open={togglePop}
            onSave={onSaveChanges}
            onClose={closePopUp}
          />
          <ImageUpload
            selectedImage={image}
            setImage={setImage}
            setToggleSwitch={setToggleSwitch}
            setActiveSlideId={setActiveSlideId}
            toggleSwitch={toggleSwitch}
            slideId={1}
          />
        </div>
      </div>
    </>
  );
};

export default About;
