import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { AiFillInfoCircle } from "react-icons/ai";
import { MdWifiCalling3 } from "react-icons/md";
import { HiBuildingLibrary } from "react-icons/hi2";
import { TbRollerSkating } from "react-icons/tb";
const Navbar = () => {
  const [toggleColor, setToggleColor] = useState("");
  return (
    <>
      <div className="bg-[#1A2338] bg-cover w-[240px] min-h-screen font-['Roboto']">
        <div className="flex flex-row">
          <div>
            <img
              src="/schoolLogo.png"
              alt="images"
              className="ml-[24px] mt-[31px]"
            />
          </div>
          <div className="font-['Roboto'] font-bold text-lg mt-[31px] text-[#fff] ml-[12px] ">
            <h1>ST. Michael’s School </h1>
          </div>
        </div>
        <ul className="flex flex-col">
          <li className="flex flex-row items-center">
            <Link
              to="/Home"
              onClick={() => setToggleColor("Home")}
              className={`${
                toggleColor === "Home" ? "bg-slate-700" : ""
              } flex items-center h-[48px] mt-10`}
            >
              <AiOutlineHome className="w-[30px] h-[30px] text-[#85A3B8] ml-[24px]" />
              <h1
                className=" lg:ml-[10px] text-[#85A3B8] 2xl:text-lg !text-sm font-medium"
                style={{ lineHeight: "15px" }}
              >
                Home Page
              </h1>
              <BiChevronDown
                className="w-[30px] h-[30px] text-[#fff] lg:mx-[2rem]"
                style={{ color: "#85A3B8" }}
              />
            </Link>
          </li>
          <li className="flex flex-row items-center">
            <Link
              to="/About"
              onClick={() => setToggleColor("About")}
              className={`${
                toggleColor === "About" ? "bg-slate-700" : ""
              } flex items-center h-[48px] mt-10`}
            >
              <AiFillInfoCircle className="w-[30px] h-[30px] ml-[24px] text-[#85A3B8]" />
              <h1 className="ml-[13px] text-[#85A3B8] 2xl:text-lg !text-sm font-medium">
                About Us
              </h1>
              <BiChevronDown
                className="w-[30px] h-[30px] text-[#fff] lg:mx-[2rem]"
                style={{ color: "#85A3B8" }}
              />
            </Link>
          </li>
          <li className="flex flex-row items-center">
            <Link
              to="/Admission"
              onClick={() => setToggleColor("Admission")}
              className={`${
                toggleColor === "Admission" ? "bg-slate-700" : ""
              } flex items-center h-[48px] mt-10`}
            >
              <HiBuildingLibrary className="w-[30px] h-[30px] text-[#85A3B8] ml-[24px]" />
              <h1
                className="ml-[13px] text-[#85A3B8] 2xl:text-lg !text-sm  font-medium"
                style={{ lineHeight: "15px" }}
              >
                Admissions
              </h1>
              <BiChevronDown
                className="w-[30px] h-[30px] text-[#fff] lg:mx-[2rem]"
                style={{ color: "#85A3B8" }}
              />
            </Link>
          </li>
          <li className="flex flex-row items-center">
            <Link
              to="/Campusbuzz"
              onClick={() => setToggleColor("Campusbuzz")}
              className={`${
                toggleColor === "Campusbuzz" ? "bg-slate-700" : ""
              } flex items-center h-[48px] mt-10`}
            >
              <TbRollerSkating className="w-[30px] h-[30px] text-[#85A3B8] ml-[24px]" />{" "}
              <h1
                className="ml-[13px] text-[#85A3B8] 2xl:text-lg !text-sm  font-medium"
                style={{ lineHeight: "15px" }}
              >
                Our Activities
              </h1>
              <BiChevronDown
                className="w-[30px] h-[30px] text-[#fff] lg:mx-[1rem]"
                style={{ color: "#85A3B8" }}
              />
            </Link>
          </li>
          <li className="flex flex-row items-center">
            <Link
              to="/ContactUsPage"
              onClick={() => setToggleColor("ContactUsPage")}
              className={`${
                toggleColor === "ContactUsPage" ? "bg-slate-700" : ""
              } flex items-center h-[48px] mt-10`}
            >
              <MdWifiCalling3 className="w-[30px] h-[30px] text-[#85A3B8] ml-[24px]" />{" "}
              <h1
                className="ml-[13px] text-[#85A3B8] 2xl:text-lg !text-sm  font-medium"
                style={{ lineHeight: "15px" }}
              >
                Contact Us
              </h1>
              <BiChevronDown
                className="w-[30px] h-[30px] text-[#fff] lg:mx-[2rem]"
                style={{ color: "#85A3B8" }}
              />
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
